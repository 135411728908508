import { render, staticRenderFns } from "./Cauhinhemail.vue?vue&type=template&id=5cab7004&scoped=true&"
import script from "./Cauhinhemail.vue?vue&type=script&lang=js&"
export * from "./Cauhinhemail.vue?vue&type=script&lang=js&"
import style0 from "./Cauhinhemail.vue?vue&type=style&index=0&id=5cab7004&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5cab7004",
  null
  
)

export default component.exports